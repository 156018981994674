.cookie-consent {
    position: fixed;
    bottom: 8px;
    left: 20px;
    width: 260px;
    padding-top: 7px;
    height: 83px;
    color: #fff;
    line-height: 20px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
    background: #292929;
    z-index: 120;
    cursor: pointer;
    border-radius: 3px
}

.allow-button {
    height: 25px;
    width: 104px;
    color: #fff;
    font-size: 12px;
    line-height: 10px;
    border-radius: 3px;
    border: 1px solid green;
    background-color: green
}
#overlay {
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 999;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: none;
    overflow: hidden
}
.text-sizety{
    font-size: 13px !important;
    font-family: sans-serif;
}
.text-css34{
    position: absolute;
    top: -45%;
}
.image-top097{
    top: -30px !important;

}
.navheight65{
    height: 11%;
}
.imagesize_898{
    position: absolute;
    height: 100%;
}
.navber_Header8799{
    position: absolute;
    left: 0%;
    padding: 0;
    z-index: 1000;
}
.ptext-234{
    font-family: sans-serif;
    font-size: 9px;
    position: absolute;
    top: 95%;
    left: 4%;
}
.ptext-235{
    font-family: sans-serif;
    font-size: 9px;
    position: absolute;
    top: 93%;
    left: 80%;
    width: 20%;
}
.text-jui876{
    text-align: center;
    font-family: sans-serif;
}
.textcolr_8765{
    font-size: 30px;
    color: #0832C3 !important;
}
.btn_color454{
    background-color:  #0832C3 !important;
}
.background-image_23{
background-image:url("../../login-v2.png");
}
.position_90{
    position: absolute;
    top: 78%;
    left: 15%;
}
.img-fluid78 {
    width: 29%;
    height: auto;
  }
  .img-fluid34{
    width: 51%;
    height: auto;
    position: absolute;
    top: -122px;
    right: 100px;
  }
  .bgcolor67{
    background: linear-gradient(0deg, #c4dafc 50%, #ffffff  100%);
  }