#zoomA {
    /* (A) OPTIONAL DIMENSIONS */
    width: 600px;
    height: auto;
   
    /* (B) ANIMATE ZOOM */
    /* ease | ease-in | ease-out | linear */
    transition: transform ease-in-out 0.3s;
  }
   
  /* (C) ZOOM ON HOVER */
  #zoomA:hover { transform: scale(1.2); }
  
  .fixedposition{
    position: fixed !important;
    left: 74%;
  }