.loader-card {
  position: fixed;
  left: 35%;
  top: 25%;
  background-color: #fefffe;
  height: 50%;
  width: 55%;
  z-index: 3;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
}
#overlay2 {
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /* display: none; */
  overflow: hidden
}
.loder-img{
  width: 250px;
    position: absolute;
    top: 40%;
    left: 24%;
}
.loader-card1 {
  position: absolute;
  left: 20%;
  top: 32%;
  height: 65%;
  width: 65%;
  z-index: 3;
}
@media (min-width: 60em) {
  .loader-card {
    height: 40%;
    margin: 6% auto;
    max-height: 14em;
    max-width: 35em;
    width: 38%;
  }
}
.loader-text {
  position: absolute;
  top: 16px;
  left: 33%;
}
