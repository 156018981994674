/* .my-custom-scrollbar {
  position: relative;
  height: 500px;
  overflow: auto;
}
.table-wrapper-scroll-y {
  display: block;
}
.scrollbar-table {
  height: 10px !important;
  overflow: scroll !important;
} */
.selected-tab{
  /* border-bottom: 3px solid blue !important; */
  border: 3px solid rgb(106, 106, 248) !important;
  /* color: blue !important;
  box-shadow: 0 0 5px 5px; */
}
#cursor, #cursor1{
  cursor: pointer!important;
}