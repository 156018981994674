tr.group,
tr.group:hover {
    background-color: #ddd !important;
}

.hiddenRow {
    padding: 0 !important;
}
.zoom {
    padding: 50px;
    transition: transform .2s;
    margin: 0 auto;
  }
  
  .zoom:hover {
    -ms-transform: scale(2.5); /* IE 9 */
    -webkit-transform: scale(2.5); /* Safari 3-8 */
    transform: scale(2.5); 
  }

   .px-9 {
    padding-right : 3px !important;
  }
   .px-9 {
    padding-left : 3px !important;
  }
 .selectall{
  position: absolute;
  left: 20px;
}
.Sync-Loader{
  position: absolute;
  top: 10%;
  left: 50%;
}
.clearfix1{
  position: absolute;
  left: 69%;
  top: 92%;
}
.select-container {
  position: relative;
  z-index: 12;
}
.paddingscreenshot
{
  padding-left: 16px !important;
}
@media print 
{
  .frm1 
  {
    height: 20px;
    transform: scale(0.7);
    zoom: 90%;
    width: 1300px !important;
    margin-right: 100px !important;
  }
  .frm2
  {
    margin-top: 580px !important;
    height: 20px;
    width: 1330px !important;
    transform: scale(0.7);
    zoom: 90%;
    margin-right: 100px !important;
  }
  /* .frm3
  {
    margin-top: 20px !important;
    height: 20px;
    transform: scale(0.7);
    width: 1200px !important;
    zoom: 100%;
  } */
  /* .frm6
  {
    margin-bottom: 20px !important;
  } */
  /* table {page-break-inside: avoid;} */
  .trhidden,.frm4, .btnhide,.tooltip-inner{
    display: none !important;
   }
}
/* @media print { 
  .fromclass
  {
    page-break-before: avoid;
    width:100%;
    height:100%;
    zoom: 85%   
 }
 .trhidden{
  display: none !important;
 }
} */
.arrow_icon{
  cursor: pointer;
}
.select-container56{
  position: relative;
  z-index: 0 !important;
}
.select-container5677{
  position: absolute;
  z-index: 0 !important;
  right: 51%;
  top: 42%;
}