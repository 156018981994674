.notify{
    padding: 0px 3px;
    border-radius: 9999px;
    background-color: rgb(45, 112, 67);
    color: white;
    top: -1px;
    left: 8px;
    position: absolute;
    font-size: 14px;
}
.dropDownTitle{
    font-weight: 600 !important;
}
.dropdown-item:active{
    background-color: rgb(129, 199, 220);
}
.optcomment{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
/* .Comment-toggle{
    display: none;
} */
.img-fluid290 {
    width: 29%;
    height: auto;
}