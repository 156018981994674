.select-container2 {
  position: relative;
  z-index: 25;
}
.select-container1 {
    position: relative;
    z-index: 20;
  }
  .select-container {
    position: relative;
    z-index: 30;
  }
  .text_align{
    text-align: center !important;
  }
  .select-container3 {
    position: relative;
    z-index: 12 !important;
  }
  .select-container4{
    position: relative;
    z-index: 8 !important;
  }
  .select-container5{
    position: relative;
    z-index: 4 !important;
  }
  .select-container8 {
    position: relative;
    z-index: 27;
  }
  .arrow_icon7{
    cursor: pointer;
  }
  .totalprice{
    position: absolute;
    top: 96%;
    left: 70%;
    z-index: 100;
  }