.loader-card {
  position: absolute;
  left: 24%;
  top: 25%;
  background-color: #fefffe;
  height: 42%;
  width: 55%;
  z-index: 3;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
}
.loder-img{
  width: 250px;
    position: absolute;
    top: 50%;
    left: 20%;
}
.loader-card1 {
  position: absolute;
  left: 20%;
  top: 32%;
  height: 65%;
  width: 65%;
  z-index: 3;
}
@media (min-width: 60em) {
  .loader-card {
    height: 40%;
    margin: 6% auto;
    max-height: 14em;
    max-width: 35em;
    width: 38%;
  }
}
.loader-text {
  position: absolute;
  top: 16px;
  left: 25%;
}
