/* @import '/home/assets/vendor/bootstrap/css/bootstrap.min.css';
@import '/home/assets/vendor/bootstrap-icons/bootstrap-icons.css';
@import '/home/assets/vendor/aos/aos.css';
@import '/home/assets/vendor/remixicon/remixicon.css';
@import '/home/assets/vendor/swiper/swiper-bundle.min.css';
@import '/home/assets/vendor/glightbox/css/glightbox.min.css';
@import '/home/assets/css/style.css'; */


.font34 {
    font-size:34px;
}

.marginleft30 {
    margin-left: 50px;
}
.header{
    background-color: rgb(255, 254, 254);
     height: 60px;
}
.img-fluid5{
    width:118%;
    height:auto ;
}
.img-fluid22{
 width: 100%;
 height: 500px;
;
}
.cookie-consent {
    position: fixed;
    bottom: 8px;
    left: 20px;
    width: 260px;
    padding-top: 7px;
    height: 83px;
    color: #fff;
    line-height: 20px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
    background: #292929;
    z-index: 120;
    cursor: pointer;
    border-radius: 3px
}

.allow-button {
    height: 25px;
    width: 104px;
    color: #fff;
    font-size: 12px;
    line-height: 10px;
    border-radius: 3px;
    border: 1px solid green;
    background-color: green
}
#overlay {
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 999;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: none;
    overflow: hidden
}